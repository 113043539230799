export interface I_DROPDOWN_VALUE {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export const OFFERING_TYPES: I_DROPDOWN_VALUE[] = [
  {
    label: "Managed Cloud Services",
    value: "managed_cloud_services",
    isDisabled: false,
  },
  { label: "Product", value: "product", isDisabled: true },
  { label: "Component", value: "component", isDisabled: true },
  { label: "Cloud Service", value: "cloud_service", isDisabled: true },
];

export const REQUIRED_FIELDS: string[] = [
  "typeOfOffering",
  "proposedProductName",
  "shortDescription",
  "productTeam",
  "pxeContact",
  "engineeringContact",
  "limitedAvailabilityDate",
  "generalAvailabilityDate",
];
