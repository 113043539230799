import { useEffect, useRef, useState } from "react";
import { VirtualAssistant } from "@patternfly/virtual-assistant";
import "./style.scss";
import UserMessage from "../../components/RHSC/UserMessage";
import BotMessage from "../../components/RHSC/BotMessage";
import Header from "../../components/RHSC/Header";
import LoadingMessage from "../../components/RHSC/LoadingMessage";
import useConversation, { IBotMessage } from "../../hooks/useConversation";
import { useHistory, useLocation } from "react-router-dom";

function RHSCAssistant() {
  const location = useLocation();
  const history = useHistory();
  const [input, setInput] = useState<string>("");
  const [showFullAiDisclaimer, setShowFullAiDisclaimer] =
    useState<boolean>(false);
  const aiDisclaimer = `Red Hat Compliance Disclaimer: You are about to utilize Red Hat's Sales Cloud AI assistant.
                  Please do not include any personal information in your chat
                  interaction. By proceeding to use the tool, you acknowledge:
                  that the tool and any information provided are only intended
                  for internal use; that information should only be shared with
                  those with a legitimate business purpose.`;

  const searchParams = new URLSearchParams(location.search);
  const stayOnNew = searchParams.get("new") === "1";
  const chatContainerRef = useRef(null);
  const {
    conversation,
    isMessageSending,
    isSubmittingFeedback,
    submittingFeedbackFor,
    handleSendMessage,
    handleFeedbackIconClick,
    resetToNewConversation,
    conversationID,
  } = useConversation({ id: "" });

  function scrollToBottom(chatContainerRef) {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }
  useEffect(() => {
    scrollToBottom(chatContainerRef);
  }, [conversation.length, isMessageSending]);

  // useEffect(() => {
  //   updateConversationId(null);
  //   if (stayOnNew) {
  //     updateLastRHSCPageVisit("/rhsc-assistant?new=1");
  //   } else {
  //     const lastVisit = getLastRHSCPageVisit();
  //     if (lastVisit?.startsWith("/rhsc-assistant/conversations/")) {
  //       history.push(lastVisit);
  //     }
  //   }
  // }, []);

  return (
    <div className="rhsc-assistant">
      <Header resetToNewConversation={resetToNewConversation} />
      <VirtualAssistant
        title="RHSC Assistant"
        message={input}
        isSendButtonDisabled={!input.trim().length}
        onChangeMessage={(_event, value) => {
          if (value.length > 300) setInput(value.substring(0, 300));
          else setInput(value);
        }}
        onSendMessage={(input) => {
          if (input.trim().length) {
            handleSendMessage(input);
            setInput("");
          }
        }}
      >
        <div className="chat-wrapper" ref={chatContainerRef}>
          {conversation.length ? (
            conversation.map((message, index) => (
              <div key={index}>
                {message.isUserMessage ? (
                  <UserMessage message={message.message} />
                ) : (
                  <BotMessage
                    handleFeedbackIconClick={handleFeedbackIconClick}
                    isSubmittingFeedback={isSubmittingFeedback}
                    submittingFeedbackFor={submittingFeedbackFor}
                    chatContainerRef={chatContainerRef}
                    conversationID={conversationID}
                  >
                    {message as IBotMessage}
                  </BotMessage>
                )}
              </div>
            ))
          ) : (
            <>
              <div className="empty-conversation">
                <div className="empty-conversation-bot-icon-wrapper">
                  <img
                    src="/images/talk-bubble.png"
                    alt="chat bubble"
                    className="empty-conversation-bot-icon"
                  />
                </div>
                <div className="empty-conversation-text">
                  Start a conversation with RHSC Assistant
                </div>
              </div>
              <div className="disclaimer-text">
                <div>
                  {showFullAiDisclaimer
                    ? aiDisclaimer
                    : `${aiDisclaimer.substring(0, 30)} `}
                  <button
                    className="text-toggler"
                    onClick={() =>
                      setShowFullAiDisclaimer(!showFullAiDisclaimer)
                    }
                  >
                    {showFullAiDisclaimer ? "View less" : "Read here"}
                  </button>
                  <br /> <br />
                  <b>
                    This bot proudly participates in the Red Hat Sales Cloud
                    Voice of the Field initiative. If you are a seller with an
                    idea or feedback, click the megaphone icon in the top right
                    corner of this chat box.
                  </b>
                </div>
                <div className="powered-by-text">
                  Powered by Granite model running on Red Hat AI
                </div>
              </div>
            </>
          )}
          {isMessageSending ? <LoadingMessage /> : null}
        </div>
      </VirtualAssistant>
    </div>
  );
}

export default RHSCAssistant;
