import {
  OutlinedThumbsDownIcon,
  ThumbsDownIcon,
  OutlinedThumbsUpIcon,
  ThumbsUpIcon,
} from "@patternfly/react-icons";
import "./style.scss";
import { Button, Spinner, TextArea, Tooltip } from "@patternfly/react-core";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";
import { logSourceLinkClickEvent } from "../../../pages/RHSCAssistant/apis";
import { generateTimestamp } from "../../../utils";

const BotMessage = ({
  children: { message, messageID, feedback, sources, otherLinks, shouldStream },
  handleFeedbackIconClick,
  isSubmittingFeedback,
  submittingFeedbackFor,
  chatContainerRef,
  conversationID,
}: any) => {
  const [showFeedbackInput, setShowFeedbackInput] = useState<boolean>(false);
  const [feedbackComment, setFeedbackComment] = useState<string>("");
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const { user } = useAuthContext();
  const userName: string = user?.name;
  const email: string = user?.email;
  let scrollTimeout = useRef(null);

  useEffect(() => {
    // Auto-scroll to the bottom when a new chunk arrives if the user isn't scrolling manually
    if (shouldStream && chatContainerRef.current && isAutoScrollEnabled) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [message, shouldStream, isAutoScrollEnabled]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          chatContainerRef.current;
        if (scrollHeight - scrollTop <= clientHeight + 10) {
          setIsAutoScrollEnabled(true);
        } else {
          setIsAutoScrollEnabled(false);
        }

        clearTimeout(scrollTimeout.current);
        scrollTimeout.current = setTimeout(() => {
          // Reset user scroll detection after a brief pause
        }, 100);
      }
    };

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const onClickFeedback = (type: string) => {
    if (type === "negative") {
      setShowFeedbackInput(!showFeedbackInput);
    } else {
      setShowFeedbackInput(false);
      handleFeedbackIconClick(messageID, "positive", "");
    }
  };

  const onClickSourceLink = (sourceLink: string, anchorText: any) => {
    const dataToSend = {
      messageId: messageID,
      conversationId: conversationID,
      userName: userName,
      sourceLinkText: anchorText,
      sourceLink: sourceLink,
      userEmail: email,
      eventDate: generateTimestamp(),
    };

    logSourceLinkClickEvent({
      endpoint: "/rhsc/source-click",
      dataToSend,
    });
  };

  const submitNegativeFeedback = () => {
    handleFeedbackIconClick(messageID, "negative", feedbackComment);
    setShowFeedbackInput(!showFeedbackInput);
  };

  const sanitizeMessage = (message: string) => {
    const formattedMessage = message
      ?.replace(/\n/g, "<br />")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/```([\s\S]*?)```/g, "<code>$1</code>")
      .replace(/`(.*?)`/g, "<code>$1</code>");

    return { __html: formattedMessage };
  };

  return (
    <div className="bot-message-wrapper">
      <div className="bot-icon-wrapper">
        <img
          src="/images/talk-bubble.png"
          alt="chat bubble"
          className="bot-icon"
        />
      </div>
      <div>
        {/* Render the message */}
        <div dangerouslySetInnerHTML={sanitizeMessage(message)} />

        {/* Show links and feedback only after streaming is complete */}
        {!shouldStream && (
          <>
            <div className="source-link-wrapper">
              {sources &&
                Object.entries(sources).map(([anchorText, href]) => (
                  <p key={anchorText}>
                    <a
                      onClick={() =>
                        onClickSourceLink(href as string, anchorText)
                      }
                      href={href as string}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {anchorText}
                    </a>
                  </p>
                ))}
              {otherLinks &&
                Object.entries(otherLinks).map(([anchorText, href]) => (
                  <p key={anchorText}>
                    <a
                      href={href as string}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        onClickSourceLink(href as string, anchorText)
                      }
                    >
                      {anchorText}
                    </a>
                  </p>
                ))}
            </div>

            {/* Show feedback icons */}
            <div className="bot-message-action-icons-wrapper">
              {feedback?.option.length ? (
                feedback.option === "positive" ? (
                  <>
                    <ThumbsUpIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                    <OutlinedThumbsDownIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <OutlinedThumbsUpIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                    <ThumbsDownIcon
                      className="bot-message-action-icon-disabled"
                      disabled
                    />
                  </>
                )
              ) : isSubmittingFeedback &&
                messageID === submittingFeedbackFor ? (
                <Spinner size="sm" />
              ) : (
                <>
                  <Tooltip content={<div>Good response</div>}>
                    <OutlinedThumbsUpIcon
                      onClick={() => onClickFeedback("positive")}
                      className="bot-message-action-icon"
                    />
                  </Tooltip>
                  <Tooltip content={<div>Bad response</div>}>
                    <OutlinedThumbsDownIcon
                      onClick={() => onClickFeedback("negative")}
                      className="bot-message-action-icon"
                    />
                  </Tooltip>
                </>
              )}
            </div>
          </>
        )}

        {/* Show feedback input if negative feedback is clicked */}
        {showFeedbackInput && (
          <div className="bot-message-feedback-form">
            <TextArea
              value={feedbackComment}
              placeholder="Provide detailed feedback (optional)"
              onChange={(_event, value) => setFeedbackComment(value)}
              aria-label="feedback comment"
              resizeOrientation="vertical"
            />
            <Button
              variant="primary"
              size="sm"
              onClick={submitNegativeFeedback}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BotMessage;
