import {
  PageSection,
  PageSectionVariants,
  Grid,
  GridItem,
  TextContent,
  Text,
  Form,
  FormGroup,
  Button,
  ActionGroup,
  TextInput,
  Icon,
  TextArea,
} from "@patternfly/react-core";
import {
  ChevronLeftIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@patternfly/react-icons";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  BasicTextInput,
  DateInput,
  DropdownInput,
} from "../../../components/InputFields";
import {
  I_DROPDOWN_VALUE,
  OFFERING_TYPES,
  REQUIRED_FIELDS,
} from "../../../constants/ManagedServices";
import VALIDATION from "../../../constants/validation";
import { getUserEmails } from "../../EVS/CreateProcess/apis";
import { utilsApiEndpoints } from "../../../apis/endpoints";
import { debounce } from "lodash";
import "./style.scss";
import moment from "moment";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import { createManagedServices } from "./apis";
import { getProcessType } from "../../../utils";
import {
  Select,
  SelectVariant,
  SelectOption,
} from "@patternfly/react-core/deprecated";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

function CreateMSW() {
  const history = useHistory();
  const alert = useContext(AlertContext);
  const { user } = useAuthContext();
  const currentProcessType = getProcessType();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [typeOfOffering, setTypeOfOffering] = useState<I_DROPDOWN_VALUE>(
    OFFERING_TYPES[0]
  );
  const [isPXEContactOpen, setIsPXEContactOpen] = useState(false);
  const [pxeContactList, setPXEContactList] = useState([]);
  const [isEngineeringContactOpen, setIsEngineeringContactOpen] =
    useState(false);
  const [engineeringContactList, setEngineeringContactList] = useState([]);
  const [isSREContactOpen, setIsSREContactOpen] = useState(false);
  const [sreContactList, setSREContactList] = useState([]);
  const [links, setLinks] = useState([{ label: "", link: "" }]);

  const [formValues, setFormValues] = useState({
    typeOfOffering: OFFERING_TYPES[0].value,
    proposedProductName: "",
    shortDescription: "",
    relatedExistingOfferings: "",
    sku: "",
    productTeam: "",
    pxeContact: "",
    engineeringContact: "",
    sreContact: "",
    techPreviewDate: "",
    limitedAvailabilityDate: "",
    generalAvailabilityDate: "",
  });

  const onPXEToggle = (isOpen, value) => {
    setIsPXEContactOpen(isOpen);
  };

  const onPXESelect = (event, selection) => {
    setFormValues({
      ...formValues,
      pxeContact: selection,
    });
    setIsPXEContactOpen(false);
  };

  const clearPXESelection = (value) => {
    setPXEContactList([]);
    setIsPXEContactOpen(false);
    setFormValues({ ...formValues, pxeContact: "" });
  };

  const onEngineeringContactToggle = (isOpen, value) => {
    setIsEngineeringContactOpen(isOpen);
  };

  const onEngineeringContactSelect = (event, selection) => {
    setFormValues({
      ...formValues,
      engineeringContact: selection,
    });
    setIsEngineeringContactOpen(false);
  };

  const clearEngineeringContactSelection = (value) => {
    setEngineeringContactList([]);
    setIsEngineeringContactOpen(false);
    setFormValues({ ...formValues, engineeringContact: "" });
  };

  const onSREContactToggle = (isOpen, value) => {
    setIsSREContactOpen(isOpen);
  };

  const onSREContactSelect = (event, selection) => {
    setFormValues({
      ...formValues,
      sreContact: selection,
    });
    setIsSREContactOpen(false);
  };

  const clearSREContactSelection = (value) => {
    setSREContactList([]);
    setIsSREContactOpen(false);
    setFormValues({ ...formValues, sreContact: "" });
  };

  const onEmailChange = (value, type) => {
    if (value.length === 0) {
      if (type === "pxe") {
        setPXEContactList([]);
      } else if (type === "engineering") {
        setEngineeringContactList([]);
      } else if (type === "sre") {
        setSREContactList([]);
      }
      return;
    }
    if (value.length >= 2) debouncedNotifyEmails(value, type);
  };

  const debouncedNotifyEmails = debounce(
    async (value: string, type: string) => {
      getUserEmails({
        url: utilsApiEndpoints.roverEmail,
        params: { searchKey: value },
        successCallback: (data) => {
          const emailDropdownValues = data.map((item) => {
            return {
              businessCardTitle: item?.businessCardTitle,
              email: item?.email,
              name: item?.name,
              rhatLocation: item?.rhatLocation,
              uid: item?.uid,
              uuid: item?.uuid,
              disabled: false,
            };
          });

          if (type === "pxe") {
            setPXEContactList(emailDropdownValues);
          } else if (type === "engineering") {
            setEngineeringContactList(emailDropdownValues);
          } else if (type === "sre") {
            setSREContactList(emailDropdownValues);
          }
        },
      });
    },
    300
  );
  const onSubmit = (value: any) => {
    setIsSubmitDisabled(true);
    value.preventDefault();

    let isDataValidated: boolean = true;
    isDataValidated = Object.entries(formValues).every(([key, value]) =>
      isDataValidated && REQUIRED_FIELDS.includes(key)
        ? value.length !== 0
        : true
    );

    if (isDataValidated) {
      const _links = links.filter((linkObj) => linkObj.link && linkObj);

      let payload = {
        ...formValues,
        createdByEmail: user?.email,
        createdBy: user?.name,
        createdDate: new Date(),
        links: _links,
      };

      createManagedServices({
        dataToSend: { managedServiceRequest: payload },
        successCallback: (data) => {
          alert.addAlert("Managed Service process submitted.", "success");
          history.push("/processes/" + currentProcessType);
        },
        failureCallback: () => {
          setIsSubmitDisabled(false);
        },
      });
    } else {
      setIsSubmitDisabled(false);
      alert.addAlert("Please fill all the required fields.", "danger");
      return;
    }
  };

  const handleChange = (index, key, value) => {
    const newFields = [...links];
    newFields[index][key] = value;
    setLinks(newFields);
  };

  const handleAddField = () => {
    setLinks([...links, { label: "", link: "" }]);
  };

  const handleDeleteField = (index) => {
    const newFields = [...links];
    newFields.splice(index, 1);
    setLinks(newFields);
  };

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <Grid>
          <GridItem span={8} style={{ display: "flex" }}>
            <button
              type="button"
              className="back-to-list-button"
              onClick={() => history.push("/processes/managedservices")}
            >
              <ChevronLeftIcon height="14px" />
            </button>
            <TextContent>
              <Text component="h1">Create Form</Text>
            </TextContent>
          </GridItem>
        </Grid>
      </PageSection>

      <div style={{ margin: 20 }}>
        <Form
          style={{
            padding: "10px 40px",
            background: "white",
            height: "100%",
          }}
        >
          {/* Type of Offering */}
          <DropdownInput
            isRequired={true}
            style={{ width: "100%" }}
            value={typeOfOffering}
            setValue={(value) => {
              setTypeOfOffering(value);
              setFormValues({ ...formValues, typeOfOffering: value.value });
            }}
            placeholder="Select Offering Type"
            label="Type of Offering"
            id="typeOfOffering"
            dropdownItems={OFFERING_TYPES || []}
          />

          <Grid hasGutter>
            {/* Proposed Product Name */}
            <GridItem span={6}>
              <BasicTextInput
                validation={(value) => {
                  return VALIDATION.length(value, 2, 180);
                }}
                maxLength={180}
                isRequired={true}
                id="proposedProductName"
                label="Proposed Product Name"
                value={formValues.proposedProductName}
                setValue={(value) => {
                  setFormValues({ ...formValues, proposedProductName: value });
                }}
              />
            </GridItem>

            {/* Short Description */}
            <GridItem span={6}>
              <FormGroup label="Short Description" isRequired={true}>
                <TextArea
                  id="shortDescription"
                  value={formValues.shortDescription}
                  onChange={(event, value) =>
                    setFormValues({ ...formValues, shortDescription: value })
                  }
                />
              </FormGroup>
            </GridItem>

            {/* Related Existing Offerings */}
            <GridItem span={6}>
              <BasicTextInput
                maxLength={180}
                isRequired={false}
                id="relatedExistingOfferings"
                label="Related Existing Offerings"
                value={formValues.relatedExistingOfferings}
                setValue={(value) => {
                  setFormValues({
                    ...formValues,
                    relatedExistingOfferings: value,
                  });
                }}
              />
            </GridItem>

            {/* SKU */}
            <GridItem span={6}>
              <BasicTextInput
                maxLength={180}
                isRequired={false}
                id="sku"
                label="SKU"
                value={formValues.sku}
                setValue={(value) => {
                  setFormValues({ ...formValues, sku: value });
                }}
              />
            </GridItem>

            {/* Product Team */}
            <GridItem span={6}>
              <BasicTextInput
                validation={(value) => {
                  return VALIDATION.length(value, 2, 180);
                }}
                maxLength={180}
                isRequired={true}
                id="productTeam"
                label="Product Team"
                value={formValues.productTeam}
                setValue={(value) => {
                  setFormValues({
                    ...formValues,
                    productTeam: value,
                  });
                }}
              />
            </GridItem>

            {/* PXE/PgM Contact */}
            <GridItem span={6}>
              <div className="select-dropdown">
                <FormGroup
                  id="pxeContact"
                  label="PXE/PgM Contact"
                  isRequired={true}
                >
                  <Select
                    variant={SelectVariant.typeahead}
                    typeAheadAriaLabel="Search by email or name"
                    onToggle={onPXEToggle}
                    required={true}
                    onSelect={onPXESelect}
                    onClear={clearPXESelection}
                    selections={formValues.pxeContact}
                    onTypeaheadInputChanged={(value) => {
                      onEmailChange(value, "pxe");
                    }}
                    isOpen={isPXEContactOpen}
                    placeholderText="Search by email or name"
                  >
                    {pxeContactList.map((option, index) => (
                      <SelectOption
                        isDisabled={option.disabled}
                        key={index}
                        value={option.email}
                        {...(option.name && {
                          description: option.name,
                        })}
                      />
                    ))}
                  </Select>
                </FormGroup>
              </div>
            </GridItem>

            {/* Engineering Contact */}
            <GridItem span={6}>
              <div className="select-dropdown">
                <FormGroup
                  id="engineeringContact"
                  label="Engineering Contact"
                  isRequired={true}
                >
                  <Select
                    variant={SelectVariant.typeahead}
                    typeAheadAriaLabel="Search by email or name"
                    onToggle={onEngineeringContactToggle}
                    required={true}
                    onSelect={onEngineeringContactSelect}
                    onClear={clearEngineeringContactSelection}
                    selections={formValues.engineeringContact}
                    onTypeaheadInputChanged={(value) => {
                      onEmailChange(value, "engineering");
                    }}
                    isOpen={isEngineeringContactOpen}
                    placeholderText="Search by email or name"
                  >
                    {engineeringContactList.map((option, index) => (
                      <SelectOption
                        isDisabled={option.disabled}
                        key={index}
                        value={option.email}
                        {...(option.name && {
                          description: option.name,
                        })}
                      />
                    ))}
                  </Select>
                </FormGroup>
              </div>
            </GridItem>

            {/* SRE Contact */}
            <GridItem span={6}>
              <div className="select-dropdown">
                <FormGroup
                  id="sreContact"
                  label="SRE Contact"
                  isRequired={false}
                >
                  <Select
                    required={false}
                    variant={SelectVariant.typeahead}
                    typeAheadAriaLabel="Search by email or name"
                    onToggle={onSREContactToggle}
                    onSelect={onSREContactSelect}
                    onClear={clearSREContactSelection}
                    selections={formValues.sreContact}
                    onTypeaheadInputChanged={(value) => {
                      onEmailChange(value, "sre");
                    }}
                    isOpen={isSREContactOpen}
                    placeholderText="Search by email or name"
                  >
                    {sreContactList.map((option, index) => (
                      <SelectOption
                        isDisabled={option.disabled}
                        key={index}
                        value={option.email}
                        {...(option.name && {
                          description: option.name,
                        })}
                      />
                    ))}
                  </Select>
                </FormGroup>
              </div>
            </GridItem>
          </Grid>

          {/* Currently planned dates (unknown is fine at the start of engagement): */}
          <Grid hasGutter>

            {/* Tech/Service Preview Date */}
            <GridItem span={6}>
              <FormGroup
                id="techPreviewDate"
                label="Tech/Service Preview Date"
                isRequired={false}
              >
                <DateInput
                  style={{ width: "100%" }}
                  id="techPreviewDateInput"
                  setValue={(value) => {
                    setFormValues({
                      ...formValues,
                      techPreviewDate: moment(value).toISOString(),
                    });
                  }}
                />
              </FormGroup>
            </GridItem>

            {/* Limited Availability Date */}
            <GridItem span={6}>
              <FormGroup
                id="limitedAvailabilityDate"
                label="Limited Availability Date"
                isRequired={true}
              >
                <DateInput
                  style={{ width: "100%" }}
                  id="limitedAvailabilityDateInput"
                  setValue={(value) => {
                    setFormValues({
                      ...formValues,
                      limitedAvailabilityDate: moment(value).toISOString(),
                    });
                  }}
                />
              </FormGroup>
            </GridItem>

            {/* General Availability Date */}
            <GridItem span={6}>
              <FormGroup
                id="generalAvailabilityDate"
                label="General Availability Date"
                isRequired={true}
              >
                <DateInput
                  style={{ width: "100%" }}
                  id="generalAvailabilityDateInput"
                  setValue={(value) => {
                    setFormValues({
                      ...formValues,
                      generalAvailabilityDate: moment(value).toISOString(),
                    });
                  }}
                />
              </FormGroup>
            </GridItem>
          </Grid>
          <FormGroup
            id="links"
            label="Provide links to any resource you have:"
          />

          {links.map((field, index) => (
            <Grid hasGutter key={index}>
              <FormGroup label={`Link #${index + 1}`} />

              <GridItem span={6}>
                <TextInput
                  type="text"
                  id={`label for link ${index + 1}`}
                  placeholder="Text"
                  value={field.label}
                  onChange={(event, value) =>
                    handleChange(index, "label", value)
                  }
                />
              </GridItem>
              <GridItem span={6}>
                {links.length > 1 && (
                  <Button
                    variant="plain"
                    onClick={() => handleDeleteField(index)}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </GridItem>
              <GridItem span={6}>
                <TextInput
                  type="text"
                  id={`link ${index + 1}`}
                  placeholder="Link"
                  value={field.link}
                  onChange={(event, value) =>
                    handleChange(index, "link", value)
                  }
                />
              </GridItem>
            </Grid>
          ))}
          <div>
            <Button
              variant="link"
              onClick={handleAddField}
              icon={
                <Icon isInline>
                  <PlusCircleIcon style={{ marginRight: 10 }} />
                </Icon>
              }
            >
              Add more
            </Button>
          </div>

          <ActionGroup
            style={{
              marginBottom: 50,
            }}
          >
            <Button
              onClick={onSubmit}
              style={{ fontWeight: "500" }}
              variant="primary"
              isDisabled={isSubmitDisabled}
              isLoading={isSubmitDisabled}
            >
              {isSubmitDisabled ? "Creating..." : "Create"}
            </Button>
          </ActionGroup>
        </Form>
      </div>
    </>
  );
}

export default CreateMSW;
