import {
  FlexItem,
  PageSection,
  PageSectionVariants,
  Form,
  TextArea,
  TextInput,
  FormGroup,
  Checkbox,
  Button,
} from "@patternfly/react-core";
import { useEffect, useState } from "react";
import SelectTypeaheadCreatable from "../../../components/InputFields/SelectTypeaheadCreatable";
import { getSbrTeams } from "./apis";
import { utilsApiEndpoints } from "../../../apis/endpoints";

const ExtendedTask = (props: any): JSX.Element => {
  const [sbrTeams, setSbrTeams] = useState([]);
  const { taskControlFields, taskControlValues, setTaskControlValues } = props;

  useEffect(() => {
    if (taskControlFields.some((field) => field.type === "selectWithCreate"))
      fetchSbrTeams();
  }, [taskControlFields]);

  const fetchSbrTeams = async () => {
    await getSbrTeams({
      url: utilsApiEndpoints.sbrTeams,
      successCallback: (response) => {
        const sbrTeamsList = response.items.map((item) => {
          return {
            value: item?.name,
            children: item?.name,
          };
        });
        setSbrTeams(sbrTeamsList);
      },
    });
  };

  return (
    <FlexItem flex={{ default: "flex_4" }}>
      <PageSection
        type="tabs"
        variant={PageSectionVariants.light}
        isWidthLimited
        style={{ minHeight: "400px", padding: 20 }}
      >
        <Form style={{ marginLeft: "10px" }}>
          {taskControlFields?.length &&
            taskControlFields.map(
              ({
                id,
                label,
                isRequired,
                type,
                options,
                disableFormLabel,
                description,
                disabled,
              }) => {
                let inputField;

                switch (type) {
                  case "textarea":
                    inputField = (
                      <TextArea
                        id={id}
                        value={taskControlValues[id]}
                        onChange={(_event, value) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "text":
                    inputField = (
                      <TextInput
                        id={id}
                        isDisabled={disabled && !taskControlValues[disabled]}
                        value={taskControlValues[id]}
                        onChange={(_event, value) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: value,
                          })
                        }
                      />
                    );
                    break;

                  case "conditionalMultiCheckbox":
                    inputField = options.map(
                      ({ label: optionLabel, id: optionId }) => {
                        return (
                          <Checkbox
                            id={optionId}
                            key={optionId}
                            isDisabled={!taskControlValues[disabled]}
                            onChange={(_event, checked) => {
                              setTaskControlValues((prevValues) => {
                                const currentArray = prevValues[id] || [];
                                let updatedArray;
                                if (checked) {
                                  updatedArray = [...currentArray, optionId];
                                } else {
                                  updatedArray = currentArray.filter(
                                    (item) => item !== optionId
                                  );
                                }
                                return {
                                  ...prevValues,
                                  [id]: updatedArray,
                                };
                              });
                            }}
                            isChecked={
                              taskControlValues[id]?.includes(optionId) || false
                            }
                            label={optionLabel}
                          />
                        );
                      }
                    );
                    break;

                  case "multiCheckbox":
                    inputField = (
                      <div key={id}>
                        {options.map(({ label: optionLabel, id: optionId }) => (
                          <Checkbox
                            key={optionId}
                            id={`${id}-${optionId}`}
                            onChange={(_event, checked) => {
                              setTaskControlValues((prevValues) => {
                                return {
                                  ...prevValues,
                                  [optionId]: checked,
                                };
                              });
                            }}
                            isChecked={taskControlValues[optionId] || false}
                            label={optionLabel}
                          />
                        ))}
                      </div>
                    );
                    break;

                  case "checkbox":
                    inputField = (
                      <Checkbox
                        id={id}
                        key={id}
                        description={description}
                        onChange={(_event, checked) => {
                          setTaskControlValues(() => ({
                            ...taskControlValues,
                            [id]: checked,
                          }));
                        }}
                        isChecked={taskControlValues[id] || false}
                        label={label}
                      />
                    );
                    break;

                  case "selectWithCreate":
                    inputField = (
                      <SelectTypeaheadCreatable
                        initialOptions={sbrTeams}
                        placeholder="Select an option"
                        onOptionSelect={(option) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: option,
                          })
                        }
                        onOptionCreate={(newOption) =>
                          setTaskControlValues({
                            ...taskControlValues,
                            [id]: newOption.value,
                          })
                        }
                      />
                    );
                    break;
                }

                if (disableFormLabel) {
                  return <>{inputField}</>;
                } else
                  return (
                    <FormGroup
                      key={id}
                      isRequired={isRequired}
                      label={label}
                      fieldId={id}
                    >
                      {inputField}
                    </FormGroup>
                  );
              }
            )}
        </Form>
      </PageSection>
    </FlexItem>
  );
};

export default ExtendedTask;
