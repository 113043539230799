import { VirtualAssistant } from "@patternfly/virtual-assistant";
import { useEffect, useRef, useState } from "react";
import BotMessage from "../../components/RHSC/BotMessage";
import UserMessage from "../../components/RHSC/UserMessage";
import Header from "../../components/RHSC/Header";
import { useParams } from "react-router-dom";
import useConversation, { IBotMessage } from "../../hooks/useConversation";
import LoadingMessage from "../../components/RHSC/LoadingMessage";
import Loader from "../../components/RHSC/Loader";
import { updateConversationId, updateLastRHSCPageVisit } from "../../utils";

const Conversation = () => {
  const { id } = useParams<{ id: string }>();
  const chatContainerRef = useRef(null);
  const [input, setInput] = useState("");

  const {
    conversation,
    isMessageSending,
    isConversationLoading,
    isSubmittingFeedback,
    submittingFeedbackFor,
    handleSendMessage,
    handleFeedbackIconClick,
    conversationID,
  } = useConversation({ id });

  function scrollToBottom(chatContainerRef) {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    scrollToBottom(chatContainerRef);
  }, [conversation.length, isMessageSending]);

  useEffect(() => {
    updateConversationId(id);
    updateLastRHSCPageVisit(`/rhsc-assistant/conversations/${id}`);
  }, [id]);

  return (
    <div className="rhsc-assistant">
      <Header />
      <VirtualAssistant
        title="RHSC Assistant"
        message={input}
        isSendButtonDisabled={!input.trim().length}
        onChangeMessage={(_event, value) => {
          if (value.length > 300) setInput(value.substring(0, 300));
          else setInput(value);
        }}
        onSendMessage={(input) => {
          if (input.trim().length) {
            handleSendMessage(input);
            setInput("");
          }
        }}
      >
        <div className="chat-wrapper" ref={chatContainerRef}>
          {isConversationLoading ? (
            <Loader />
          ) : (
            conversation.map((message, index) => (
              <div key={index}>
                {message.isUserMessage ? (
                  <UserMessage message={message.message} />
                ) : (
                  <BotMessage
                    handleFeedbackIconClick={handleFeedbackIconClick}
                    chatContainerRef={chatContainerRef}
                    isSubmittingFeedback={isSubmittingFeedback}
                    submittingFeedbackFor={submittingFeedbackFor}
                    conversationID={conversationID}
                  >
                    {message as IBotMessage}
                  </BotMessage>
                )}
              </div>
            ))
          )}
          {isMessageSending ? <LoadingMessage /> : null}
        </div>
      </VirtualAssistant>
    </div>
  );
};

export default Conversation;
