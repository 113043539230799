import Processes from "../pages/Processes";
import CreateProcess from "../pages/Common/CreateProcess";
import ProcessDetails from "../pages/Common/ProcessDetails";
import ProcessList from "../pages/Common/ProcessList";
import TaskDetails from "../pages/Common/TaskDetails";
import TaskList from "../pages/Common/TaskList";
import AccountPlanning from "../pages/AccountPlanning/CreateAccountPlan";
import ReviewAccountPlan from "../pages/AccountPlanning/ReviewAccountPlan";
import RHSCAssistant from "../pages/RHSCAssistant";
import Conversations from "../pages/RHSCAssistant/ConversationsList";
import Conversation from "../pages/RHSCAssistant/Conversation";
import RHSCAssistantDev from "../pages/RHSCAssistantDev";
import ConversationsDev from "../pages/RHSCAssistantDev/ConversationsList";
import ConversationDev from "../pages/RHSCAssistantDev/Conversation";

interface RouteDataItem {
  path: string;
  name: string;
  component: React.FC;
  exact: boolean;
}

export const routes: RouteDataItem[] = [
  {
    path: "/processes",
    name: "Processes",
    component: Processes,
    exact: true,
  },
  {
    path: "/create-process/:processType",
    name: "Create Process",
    component: CreateProcess,
    exact: true,
  },
  {
    path: "/processes/:processType",
    name: "Process List",
    component: ProcessList,
    exact: true,
  },
  {
    path: "/processes/:processType/:id",
    name: "Process Details",
    component: ProcessDetails,
    exact: true,
  },
  {
    path: "/tasks/:processType",
    name: "Tasks List",
    component: TaskList,
    exact: true,
  },
  {
    path: "/tasks/:processType/:id",
    name: "Task Details",
    component: TaskDetails,
    exact: true,
  },
  {
    path: "/account-planning",
    name: "Account Planning",
    component: AccountPlanning,
    exact: true,
  },
  {
    path: "/account-planning/:id",
    name: "Account Plan review",
    component: ReviewAccountPlan,
    exact: true,
  },
  {
    path: "/rhsc-assistant",
    name: "RHSC Assistant",
    component: RHSCAssistant,
    exact: true,
  },
  {
    path: "/rhsc-assistant/conversations",
    name: "RHSC Assistant conversations list",
    component: Conversations,
    exact: true,
  },
  {
    path: "/rhsc-assistant/conversations/:id",
    name: "RHSC Assistant conversation",
    component: Conversation,
    exact: true,
  },
  // {
  //   path: "/rhsc-assistant-dev",
  //   name: "RHSC Assistant",
  //   component: RHSCAssistantDev,
  //   exact: true,
  // },
  // {
  //   path: "/rhsc-assistant-dev/conversations",
  //   name: "RHSC Assistant conversations list",
  //   component: ConversationsDev,
  //   exact: true,
  // },
  // {
  //   path: "/rhsc-assistant-dev/conversations/:id",
  //   name: "RHSC Assistant conversation",
  //   component: ConversationDev,
  //   exact: true,
  // },
];
